* {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #4b4b4b;
}
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #f8f9fb;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
  scrollbar-width: 1px; /* Prevent horizontal scroll */
}


@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes nav {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.float-1,
.float1-link {
  z-index: 9;
  position: fixed;
  right: 1rem;
  bottom: 14rem;
  background-color: #2c52a0;
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  box-sizing: border-box;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.float1-link i {
  transition: transform 0.4s ease-in-out;
  font-size: 1.9rem;
}

.float-1:hover i {
  transform: rotateZ(135deg) translateY(2px);
}
.float-1,
.float2-link {
  z-index: 9;
  position: fixed;
  right: 1rem;
  bottom: 8rem;
  background-color: #25d366;
  width: 3.7rem;
  height: 3.7rem;
  border-radius: 100%;
  box-sizing: border-box;
  color: #ffffff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.float2-link i {
  font-size: 1.9rem;
}
.pnf {
  width: 88%;
  margin: auto;
  color: #636262;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.pnf-div {
  padding: 1.5rem;
  width: 38%;
  height: 240px;
}
.pnf-p1 {
  font-size: 1.5rem;
  color: #000000;
}
.pnf a {
  color: #25d366;
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  * {
    scrollbar-width: none;
    font-size: 1rem;
    font-weight: 300;
  }
  .pnf-div {
    padding: 1.5rem;
    width: 85%;
    height: auto;
  }
  .float-1,
.float1-link {
  bottom: 12%;
  width: 3rem;
  height: 3rem;
}
.float-1,
.float2-link {
  bottom: 3%;
  width: 3rem;
  height: 3rem;
}
}
